import React from "react";
import "./style.css";
import { Link } from "react-router-dom";

export const ServicesPage = () => {
  return (
    <div className="services-page">
      <div className="div">
        <div className="group">
          <div className="navbar">
            <Link to="/"><div className="text-wrapper">Product</div></Link>
            <Link to="/services"><div className="text-wrapper-2">Services</div></Link>
            <Link to="/about"><div className="text-wrapper-3">About Us</div></Link>
            <Link to="/investors"><div className="text-wrapper-4">For Investors</div></Link>
            <div className="overlap-group-wrapper">
              <div className="overlap-group">
                <Link to="/Contact"><div className="text-wrapper-5">Contact</div></Link>
              </div>
            </div>
          </div>
        </div>
        <div className="overlap-2">
          <div className="text">{""}</div>
          <div className="group-2">
            <div className="group-3">
              <div className="text-wrapper-6">APR Generator</div>
              <div className="overlap-group-2">
                <p className="unlock-the-potential">
                  Unlock the potential of your pharmaceutical operations with our &#39;APR Generator&#39; software
                  subscription. This robust tool is engineered to revolutionize the way you handle Annual Product
                  Reviews, bringing automation and efficiency to the forefront of regulatory compliance. With &#39;APR
                  Generator,&#39; you’re not just keeping up with industry standards; you’re setting them.
                </p>
                <div className="rectangle" />
              </div>
            </div>
            <img className="mockup-kiu" alt="Mockup kiu" src="/img/mockup-kiu-2-1.svg" />
          </div>
        </div>
        <a href="/">
          <img className="logo" alt="Logo" src="/img/logo.svg" />
        </a>
        <div className="group-4">
          <div className="group-5">
            <p className="project-management">Project Management &amp; Consultancy Services</p>
            <div className="rectangle-2" />
            <div className="group-6">
              <div className="div-wrapper">
                <div className="overlap-group-3">
                  <div className="text-wrapper-7">Project Management</div>
                  <p className="p">
                    Our Project Management services offer strategic guidance and meticulous oversight, ensuring your
                    projects achieve their objectives with precision.
                  </p>
                </div>
              </div>
              <div className="overlap-wrapper">
                <div className="overlap-3">
                  <div className="text-wrapper-8">Validation Consultants</div>
                  <p className="text-wrapper-9">
                    The expertise of our Validation Consultants ensures your operations adhere to the highest
                    compliance standards.
                  </p>
                </div>
              </div>
              <div className="group-7">
                <div className="overlap-4">
                  <div className="rectangle-3" />
                  <div className="text-wrapper-10">System Deployment</div>
                  <p className="text-wrapper-11">
                    When it comes to System Deployment, rely on our team for seamless integration of new solutions,
                    ensuring operational continuity and success.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <p className="text-wrapper-12">
            With our suite of services, your company will be equipped to navigate the complexities of pharmaceutical
            compliance with ease and expertise.
          </p>
        </div>
        <div className="group-8">
          <div className="group-9">
            <div className="text-wrapper-13">QA Validation /Copilot</div>
            <p className="QA-validation">
              QA Validation Copilot is an AI-powered solution design to provide answers to questions related to
              Computer Systems Validation and Risk Management for Computer Systems. With a body of knowledge based on
              industry&#39;s best practices, the QA Validation Copilot is the perfect complement to junior resources
              while also providing value to more experienced resources. The QA Validation Copilot will soon be updated
              to provide assistance writing select validation deliverables, such as change control records and
              validation plans.
            </p>
            <div className="rectangle-4" />
          </div>
          <img className="img" alt="Mockup kiu" src="/img/mockup-kiu-1.svg" />
        </div>
        <div className="group-10">
          <div className="overlap-5">
            <p className="text-wrapper-14">
              © 2023 Kiu AI Systems, Inc. All rights reserved
            </p>
            <div className="group-11">
              <a
                href="https://maps.app.goo.gl/n1EU8h2FX28v1ZH46"
                className="text-wrapper-15"
                target="_blank"
                rel="noopener noreferrer"
              >
                4242 Six Forks Rd, Suite 1550 Raleigh, NC 27609
              </a>
              <img className="vector" alt="Vector" src="/img/vector-2.svg" />
            </div>
            <div className="group-12">
              <a
                className="text-wrapper-16"
                href="https://www.kiu.ai"
                target="_blank"
                rel="noopener noreferrer"
              >
                WWW.KIU.AI
              </a>
              <img className="vector-2" alt="Vector" src="/img/vector-1.svg" />
            </div>
            <div className="group-13">
              <a href="tel:+17866028048" className="text-wrapper-17">
                +1 (786) 602-8048
              </a>
              <img className="group-14" alt="Group" src="/img/group.svg" />
            </div>
            <div className="group-15">
            <a href="mailto:Adrian@KIU.ai" className="text-wrapper-18">Adrian@KIU.ai</a>
              <img className="vector-3" alt="Vector" src="/img/vector.svg" />
            </div>
            <div className="rectangle-5" />
            <div className="rectangle-6" />
            <div className="rectangle-7" />
          </div>
        </div>
      </div>
    </div>
  );
};
