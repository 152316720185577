
/*
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ProductPage } from "./screens/ProductPage";
import { ServicesPage } from "./screens/ServicesPage";
import { AboutUsPage } from "./screens/AboutUsPage";
import { ForInvestorsPage } from "./screens/ForInvestorsPage";
import { ContactUsPage } from "./screens/ContactUsPage";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<ProductPage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/about" element={<AboutUsPage />} />
        <Route path="/investors" element={<ForInvestorsPage />} />
        <Route path="/contact" element={<ContactUsPage />} />
      </Routes>
    </Router>
  );
};

const appRoot = document.getElementById("app");
ReactDOM.createRoot(appRoot).render(<App />);

/*
import React from "react";
import ReactDOMClient from "react-dom/client";
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import { ProductPage } from "./screens/ProductPage";
import { ServicesPage } from "./screens/ServicesPage";
import { AboutUsPage } from "./screens/AboutUsPage";
import { ForInvestorsPage } from "./screens/ForInvestorsPage";
import { ContactUsPage } from "./screens/ContactUsPage";





const app = document.getElementById("app");
const root = ReactDOMClient.createRoot(app);
root.render(<ProductPage />); /*
root.render(<ServicesPage />);
root.render(<AboutUsPage />);
root.render(<ForInvestorsPage />);
root.render(<ContactUsPage />);
*/

import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import { ProductPage } from "./screens/ProductPage";
import { ServicesPage } from "./screens/ServicesPage";
import { AboutUsPage } from "./screens/AboutUsPage";
import { ForInvestorsPage } from "./screens/ForInvestorsPage";
import { ContactUsPage } from "./screens/ContactUsPage";

const app = document.getElementById("app");
const root = createRoot(app);

root.render(
    <Router>
      <Routes>
        <Route path="/" element={<ProductPage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/about" element={<AboutUsPage />} />
        <Route path="/investors" element={<ForInvestorsPage />} />
        <Route path="/contact" element={<ContactUsPage />} />
      </Routes>
    </Router>
  );