  import React from "react";
import "./style.css";
import { Link } from "react-router-dom";

export const AboutUsPage = () => {
  return (
    <div className="about-us-page">
      <div className="div">
        <div className="group">
          <div className="navbar">
            <Link to="/"><div className="text-wrapper">Product</div></Link>
            <Link to="/services"><div className="text-wrapper-2">Services</div></Link>
            <Link to="/about"><div className="text-wrapper-3">About Us</div></Link>
            <Link to="/investors"><div className="text-wrapper-4">For Investors</div></Link>
            <div className="overlap-group-wrapper">
              <div className="overlap-group">
                <Link to="/Contact"><div className="text-wrapper-5">Contact</div></Link>
              </div>
            </div>
          </div>
        </div>
        <div className="overlap-2">
          <div className="text">{""}</div>
          <div className="group-2">
            <div className="group-3">
              <div className="flexcontainer">
                <p className="span-wrapper">
                  <span className="span">
                    Biography
                    <br />
                  </span>
                </p>
                <p className="span-wrapper">
                  <span className="span">
                    Adrian Vazquez is co-founder and CEO of Kiu AI Systems. He has 30 years of progressive experience
                    in the pharmaceutical and medical devices industries including in Quality Control, Validation,
                    Project and Program Management, and Quality Assurance and Compliance for Computer Systems. He
                    holds a bachelor of science degree from the University of Puerto Rico and an MBA from Florida
                    International University.
                  </span>
                </p>
              </div>
              <div className="text-wrapper-6">Adrian Vazquez</div>
              <div className="rectangle" />
            </div>
            <img className="img" alt="Group" src="/img/group-77.svg" />
            <div className="group-4" />
          </div>
        </div>
        <a href="/">
          <img className="logo" alt="Logo" src="/img/logo.svg" />
        </a>
        <div className="group-5">
          <div className="group-6">
            <div className="flexcontainer-2">
              <p className="p">
                <span className="span">
                  Biography
                  <br />
                </span>
              </p>
              <p className="p">
                <span className="span">
                  Offering more than two decades of senior executive leadership in the life science Industry, Mike is
                  known by colleagues and clients as an influential thought leader for insightful positive and
                  strategic change.&nbsp;&nbsp;As the driving force behind the Institute of Validation Technology
                  (IVT) for more than 10 years, Mike was sought after by hundreds of organizations to advise on a
                  variety of FDA regulatory and compliance issues. Educational credits include a master&#39;s degree
                  in education from Cambridge College in Boston.
                </span>
              </p>
            </div>
            <div className="text-wrapper-7">Mike Berube</div>
            <div className="rectangle-2" />
          </div>
          <img className="group-7" alt="Group" src="/img/group-77.svg" />
          <div className="group-8" />
        </div>
        <div className="overlap-wrapper">
          <div className="overlap-4">
            <p className="text-wrapper-8">
              © 2023 Kiu AI Systems, Inc. All rights reserved
            </p>
            <div className="group-9">
              <a
                href="https://maps.app.goo.gl/n1EU8h2FX28v1ZH46"
                className="text-wrapper-9"
                target="_blank"
                rel="noopener noreferrer"
              >
                4242 Six Forks Rd, Suite 1550 Raleigh, NC 27609
              </a>
              <img className="vector" alt="Vector" src="/img/vector-2.svg" />
            </div>
            <div className="group-10">
              <a
                className="text-wrapper-10"
                href="https://www.kiu.ai"
                target="_blank"
                rel="noopener noreferrer"
              >
                WWW.KIU.AI
              </a>
              <img className="vector-2" alt="Vector" src="/img/vector-1.svg" />
            </div>
            <div className="group-11">
              <a href="tel:+17866028048" className="text-wrapper-11">
                +1 (786) 602-8048
              </a>
              <img className="group-12" alt="Group" src="/img/group.svg" />
            </div>
            <div className="group-13">
            <a href="mailto:Adrian@KIU.ai" className="text-wrapper-12">Adrian@KIU.ai</a>
              <img className="vector-3" alt="Vector" src="/img/vector.svg" />
            </div>
            <div className="rectangle-5" />
            <div className="rectangle-6" />
            <div className="rectangle-7" />
          </div>
        </div>
      </div>
    </div>
  );
};
