import React, { useState, useEffect } from "react";
import "./style.css";
import { Link } from "react-router-dom";

export const ForInvestorsPage = () => {
  // State to manage form data
  const [formData, setFormData] = useState({
    name: "",
    lastName: "",
    email: "",
    message: "",
  });

  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(null); // "success" or "error"
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    if (status) {
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
        setStatus(null);
      }, 2000);
    }
  }, [status]);

  // Event handler for form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

    // Event handler for form submission
    const handleSubmit = async () => {
      setLoading(true);
      try {
        // Send email using SMTP.js with a secure token
        await Email.send({
          SecureToken : "681f847a-6fda-440e-8fba-0f87464fb7cc",
          To: "mailalerts0@gmail.com",
          From: "mailalerts0@gmail.com",
          Subject: "New Message from KIU",
          Body: `Name: ${formData.name}\nLast Name: ${formData.lastName}\nEmail: ${formData.email}\nMessage: ${formData.message}`,
        });
  
        setStatus("success");
      } catch (error) {
        console.error("Error:", error);
        setStatus("error");
      } finally {
        setLoading(false);
      }
    };
    
  /*
  // Event handler for form submission
  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await fetch('http://localhost:3000/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();
      console.log(result);
      
      if (result.success) {
        setStatus("success");
        // Optionally clear the form data here
        // setFormData({ name: "", lastName: "", email: "", message: "" });
      } else {
        setStatus("error");
      }
    } catch (error) {
      console.error("Error:", error);
      setStatus("error");
    } finally {
     setLoading(false);
    }
  }; 
  */
  
  return (
    <div className="for-investors-page">
      <div className="div">
        <div className="overlap">
          <div className="group">
            <div className="navbar">
              <Link to="/"><div className="text-wrapper">Product</div></Link>
              <Link to="/services"><div className="text-wrapper-2">Services</div></Link>
              <Link to="/about"><div className="text-wrapper-3">About Us</div></Link>
              <Link to="/investors"><div className="text-wrapper-4">For Investors</div></Link>
              <div className="overlap-group-wrapper">
                <div className="overlap-group">
                  <Link to="/Contact"><div className="text-wrapper-5">Contact</div></Link>
                </div>
              </div>
            </div>
          </div>
          <a href="/">
            <img className="logo" alt="Logo" src="/img/logo.svg" />
          </a>
          <div className="overlap-2">
            <div className="text">{""}</div>
            <div className="group-2">
              <div className="group-wrapper">
                <div className="group-3">
                  <div className="overlap-3">
                    <div className="frame">
                      <img className="vector" alt="Vector" src="/img/vector-18.svg" />
                      <div className="overlap-4">
                        <div className="overlap-group-2">
                          <img className="img" alt="Vector" src="/img/vector-18.svg" />
                          <img className="vector-2" alt="Vector" src="/img/vector-18.svg" />
                          <img className="vector-3" alt="Vector" src="/img/vector-18.svg" />
                          <img className="vector-4" alt="Vector" src="/img/vector-18.svg" />
                          <img className="vector-5" alt="Vector" src="/img/vector-16.svg" />
                          <img className="vector-6" alt="Vector" src="/img/vector-15.svg" />
                          <img className="vector-7" alt="Vector" src="/img/vector-14.svg" />
                          <img className="vector-8" alt="Vector" src="/img/vector-112.svg" />
                          <img className="vector-9" alt="Vector" src="/img/vector-7.svg" />
                          <img className="vector-10" alt="Vector" src="/img/vector-113.svg" />
                          <img className="vector-11" alt="Vector" src="/img/vector-111.svg" />
                          <div className="text-wrapper-6">6</div>
                          <div className="text-wrapper-7">15</div>
                          <img className="vector-12" alt="Vector" src="/img/vector-12.svg" />
                          <img className="vector-13" alt="Vector" src="/img/vector-12.svg" />
                          <img className="vector-14" alt="Vector" src="/img/vector-12.svg" />
                        </div>
                        <img className="vector-15" alt="Vector" src="/img/vector-13.svg" />
                        <img className="vector-16" alt="Vector" src="/img/vector-114.svg" />
                        <div className="text-wrapper-8">2</div>
                        <div className="text-wrapper-9">25</div>
                      </div>
                      <img className="vector-17" alt="Vector" src="/img/vector-17.svg" />
                      {/*<img className="vector-18" alt="Vector" src="/img/vector-9.png" />*/}
                    </div>
                    <div className="text-wrapper-10">0</div>
                    <div className="text-wrapper-11">5</div>
                    <div className="text-wrapper-12">5 000</div>
                    <div className="text-wrapper-13">10 000</div>
                    <div className="text-wrapper-14">15 000</div>
                    <div className="text-wrapper-15">20 000</div>
                    <div className="text-wrapper-16">25 000</div>
                    <div className="text-wrapper-17">10</div>
                    <div className="text-wrapper-18">15</div>
                    <div className="text-wrapper-19">20</div>
                    <div className="text-wrapper-20">25</div>
                    <div className="text-wrapper-21">30</div>
                  </div>
                  <img className="vector-19" alt="Vector" src="/img/vector-3.svg" />
                  <img className="vector-20" alt="Vector" src="/img/vector-115.svg" />
                  <img className="vector-21" alt="Vector" src="/img/vector-116.svg" />
                  <div className="text-wrapper-22">2024</div>
                  <div className="text-wrapper-23">2025</div>
                  <div className="text-wrapper-24">2026</div>
                  <div className="text-wrapper-25">2027</div>
                  <div className="text-wrapper-26">APR Generator</div>
                  <div className="text-wrapper-27">Validation and Compliance Copilot</div>
                  <div className="text-wrapper-28">Number of Users</div>
                </div>
              </div>
              <div className="group-4">
                <div className="text-wrapper-29">Pharmaceutical industry</div>
                <div className="text-wrapper-30">Financial Projections (000’s)</div>
                <div className="rectangle" />
              </div>
            </div>
          </div>
          <div className="group-5">
            <div className="group-6">
              <div className="flexcontainer">
                <p className="span-wrapper">
                  <span className="span">
                    Total spend on <br />
                  </span>
                </p>
                <p className="span-wrapper">
                  <span className="span">medication in US</span>
                </p>
              </div>
              <div className="div-wrapper">
                <div className="text-wrapper-31">603B</div>
              </div>
            </div>
            <div className="group-7">
              <div className="text-wrapper-32">CAGR</div>
              <div className="overlap-5">
                <div className="ellipse" />
                <div className="text-wrapper-33">5.7%</div>
              </div>
            </div>
            <div className="group-8">
              <div className="text-wrapper-34">Median cost</div>
              <div className="overlap-6">
                <div className="text-wrapper-35">19M</div>
              </div>
            </div>
            <div className="group-9">
              <div className="flexcontainer-2">
                <p className="span-wrapper">
                  <span className="span">
                    Around 380 companies <br />
                  </span>
                </p>
                <p className="span-wrapper">
                  <span className="span">
                    apply for FDA approval. <br />
                  </span>
                </p>
                <p className="span-wrapper">
                  <span className="span">Chance of success</span>
                </p>
              </div>
              <div className="overlap-7">
                <div className="text-wrapper-36">10%</div>
              </div>
            </div>
          </div>
          <div className="overlap-8">
            <div className="text-2">{""}</div>
            <div className="group-10">
              <div className="group-11">
                <div className="overlap-group-3">
                  <div className="text-wrapper-37">44%</div>
                  <div className="text-wrapper-38">29%</div>
                  <div className="text-wrapper-39">22%</div>
                  <div className="text-wrapper-40">5%</div>
                </div>
              </div>
              <div className="group-12">
                <div className="group-13">
                  <div className="flexcontainer-3">
                    <p className="p">
                      <span className="text-wrapper-41">
                        Employee Related Expenses <br />
                      </span>
                    </p>
                    <p className="p">
                      <span className="text-wrapper-41">
                        Salaries &amp; Wages Operating
                        <br />
                      </span>
                    </p>
                    <p className="p">
                      <span className="text-wrapper-41">
                        Expenses
                        <br />
                      </span>
                    </p>
                    <p className="p">
                      <span className="text-wrapper-41">Direct Costs </span>
                    </p>
                  </div>
                  <div className="rectangle-2" />
                  <div className="rectangle-3" />
                  <div className="rectangle-4" />
                  <div className="rectangle-5" />
                </div>
                <div className="text-wrapper-42">FUNDING BREAKDOWN</div>
                <p className="we-are-seeking-for">
                  We are seeking for $2.5 million seed-round investment to revolutionize the pharmaceutical
                  industry&#39;s approach to Annual Product Reviews. Your investment will supercharge our &#39;APR
                  Generator&#39; software development, attract top-tier talent, and sustain our daily operations,
                  propelling us toward industry dominance
                </p>
                <div className="rectangle-6" />
              </div>
            </div>
          </div>
          <div className="overlap-wrapper">
            <div className="flexcontainer-wrapper">
              <div className="flexcontainer-4">
                <p className="text-3">
                  <span className="text-wrapper-43">Dive into an untapped market ripe with potential.</span>
                  <span className="text-wrapper-44">
                    {" "}
                    The pharmaceutical industry craves efficient compliance solutions, and we are poised to conquer this
                    space. <br />
                  </span>
                </p>
                <p className="text-3">
                  <span className="text-wrapper-44">
                    Our &#39;APR Generator,&#39; along with our innovative Validation Copilot, currently in the proof of
                    concept stage, are not just solutions – they are game changers. Both are primed for development into
                    MVPs, with the right investment. Join us as we revolutionize regulatory compliance and seize this
                    golden opportunity.
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="group-14">
            <div className="overlap-9">
              <div className="frame-2">
                <div className="overlap-group-4">
                  <div className="ellipse-2" />
                  <div className="ellipse-3" />
                </div>
                <div className="overlap-10">
                  <img className="img-2" alt="Carbon location" src="/img/carbon-location-filled.png" />
                  <div className="group-15">
                    <img className="vector-22" alt="Vector" src="/img/vector.svg" />
                    <p className="element-six-forks-rd">
                      4242 Six Forks Rd, Suite 1550
                      <br />
                      Raleigh, NC 27609
                    </p>
                  </div>
                </div>
                <div className="group-16">
                  <img className="img-2" alt="Bxs phone call" src="/img/bxs-phone-call.svg" />
                  <div className="text-wrapper-45">+1 (786) 602-8048</div>
                </div>
                <div className="group-17">
                  <img className="img-2" alt="Ic sharp email" src="/img/ic-sharp-email.svg" />
                  <div className="text-wrapper-45">Mike@KIU.ai / Adrian@KIU.ai</div>
                </div>
                <div className="text-wrapper-46">CONTACT US</div>
                <div className="text-wrapper-47">Contact information</div>
              </div>
              <button className="overlap-11" onClick={handleSubmit}>
                <div className="text-wrapper-48">SEND</div>
              </button>
              <div className="overlap-12">
                {/*<div className="text-wrapper-49">Full Name</div>*/}
                <input
                  type="text"
                  placeholder="First Name"
                  value={formData.name}
                  onChange={handleChange}
                  name="name"
                />
              </div>
              <div className="overlap-13">
                {/*<div className="text-wrapper-49">E-mail</div>*/}
                <input
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  value={formData.lastName}
                  onChange={handleChange}
                />
              </div>
              <div className="overlap-14">
                {/*<div className="text-wrapper-50">Phone number</div>*/}
                <input
                  type="email"
                  name="email"
                  placeholder="E-mail"
                  value={formData.email}
                  onChange={handleChange}
              />
              </div>
              <div className="overlap-15">
                {/*<div className="text-wrapper-50">Message</div>*/}
                <textarea
                  name="message"
                  placeholder="Write your message.."
                  value={formData.message}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>
        {/*<div className="overlap-16">
          <img className="rectangle-7" alt="Rectangle" src="/img/rectangle-65.png" />
          <img className="rectangle-8" alt="Rectangle" src="/img/rectangle-65.png" />
        </div>*/}
        <div className="group-18">
          <div className="overlap-17">
            <p className="text-wrapper-51">
              © 2023 Kiu AI Systems, Inc. All rights reserved
            </p>
            <div className="group-19">
              <a
                href="https://maps.app.goo.gl/n1EU8h2FX28v1ZH46"
                className="text-wrapper-52"
                target="_blank"
                rel="noopener noreferrer"
              >
                4242 Six Forks Rd, Suite 1550 Raleigh, NC 27609
              </a>
              <img className="vector-23" alt="Vector" src="/img/vector-25.svg" />
            </div>
            <div className="group-20">
              <a
                className="text-wrapper-53"
                href="https://www.kiu.ai"
                target="_blank"
                rel="noopener noreferrer"
              >
                WWW.KIU.AI
              </a>
              <img className="vector-24" alt="Vector" src="/img/vector-24.svg" />
            </div>
            <div className="group-21">
              <a href="tel:+17866028048" className="text-wrapper-54">
                +1 (786) 602-8048
              </a>
              <img className="group-22" alt="Group" src="/img/group.svg" />
            </div>
            <div className="group-23">
              <a href="mailto:Adrian@KIU.ai" className="text-wrapper-55">Adrian@KIU.ai</a>
              <img className="vector-25" alt="Vector" src="/img/vector-23.svg" />
            </div>
            <div className="rectangle-9" />
            <div className="rectangle-10" />
            <div className="rectangle-11" />
          </div>
        </div>
      </div>
      {/* Loading screen */}
      {loading && <div className="loading">Submitting...</div>}
      {/* Popup to display success or error message */}
      {showPopup && (
        <div className={`popup ${status}`}>
          {status === "success" ? "Message sent successfully!" : "Error sending message."}
        </div>
      )}
    </div>
  );
};
