import React, { useState, useEffect } from "react";
import "./style.css";
import { Link } from "react-router-dom";

export const ContactUsPage = () => {
  // State to manage form data
  const [formData, setFormData] = useState({
    name: "",
    lastName: "",
    email: "",
    message: "",
  });

  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(null); // "success" or "error"
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    if (status) {
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
        setStatus(null);
      }, 2000);
    }
  }, [status]);

  // Event handler for form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Event handler for form submission
  const handleSubmit = async () => {
    setLoading(true);
    try {
      // Send email using SMTP.js with a secure token
      await Email.send({
        SecureToken : "681f847a-6fda-440e-8fba-0f87464fb7cc",
        To: "mailalerts0@gmail.com",
        From: "mailalerts0@gmail.com",
        Subject: "New Message from KIU",
        Body: `Name: ${formData.name}\nLast Name: ${formData.lastName}\nEmail: ${formData.email}\nMessage: ${formData.message}`,
      });

      setStatus("success");
    } catch (error) {
      console.error("Error:", error);
      setStatus("error");
    } finally {
      setLoading(false);
    }
  };

  /*
    // Event handler for form submission
    const handleSubmit = async () => {
      setLoading(true);
      try {
        const response = await fetch('http://localhost:3000/send-email', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });
  
        const result = await response.json();
        console.log(result);
  
        if (result.success) {
          setStatus("success");
          // Optionally clear the form data here
          // setFormData({ name: "", lastName: "", email: "", message: "" });
        } else {
          setStatus("error");
        }
      } catch (error) {
        console.error("Error:", error);
        setStatus("error");
      } finally {
       setLoading(false);
      }
    };
  */
  return (
    <div className="contact-us-page">
      <div className="div">
        <div className="overlap">
          <div className="group">
            <div className="navbar">
              <Link to="/"><div className="text-wrapper">Product</div></Link>
              <Link to="/services"><div className="text-wrapper-2">Services</div></Link>
              <Link to="/about"><div className="text-wrapper-3">About Us</div></Link>
              <Link to="/investors"><div className="text-wrapper-4">For Investors</div></Link>
              <div className="overlap-group-wrapper">
                <div className="overlap-group">
                  <Link to="/Contact"><div className="text-wrapper-5">Contact</div></Link>
                </div>
              </div>
            </div>
          </div>
          <a href="/">
            <img className="logo" alt="Logo" src="/img/logo.svg" />
          </a>
          <div className="group-2">
            <div className="overlap-wrapper">
              <div className="overlap-2">
                <div className="text">{""}</div>
                <div className="div-wrapper">
                  <div className="overlap-3">
                    <div className="div-wrapper">
                      <div className="overlap-4">
                        <div className="frame-wrapper">
                          <div className="frame">
                            <div className="overlap-group-2">
                              <div className="ellipse" />
                              <div className="ellipse-2" />
                            </div>
                            <div className="text-wrapper-6">Contact Information</div>
                            <div className="group-3">
                              <img
                                className="carbon-location"
                                alt="Carbon location"
                                src="/img/carbon-location-filled.svg"
                              />
                              <div className="group-4">
                                <img className="img" alt="Bxs phone call" src="/img/bxs-phone-call.svg" />
                                <div className="text-wrapper-7">+1 (786) 602-8048</div>
                              </div>
                              <div className="group-5">
                                <img className="img" alt="Ic sharp email" src="/img/ic-sharp-email.svg" />
                                <div className="text-wrapper-7">Mike@KIU.ai / Adrian@KIU.ai</div>
                              </div>
                              <p className="element-six-forks-rd">
                                4242 Six Forks Rd, Suite 1550
                                <br />
                                Raleigh, NC 27609
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="group-6">
                          <img className="vector" alt="Vector" src="/img/vector-120.svg" />
                          {/*<img className="vector-2" alt="Vector" src="/img/vector-123.svg" />*/}
                          <div className="text-wrapper-8">First Name</div>
                          <input
                            type="text"
                            name="name"
                            placeholder="Your First Name"
                            value={formData.name}
                            onChange={handleChange}
                            tabIndex={1}
                          />
                        </div>
                        <div className="group-7">
                          <img className="vector" alt="Vector" src="/img/vector-122.svg" />
                          <div className="text-wrapper-8">Email</div>
                          <input
                            type="email"
                            name="email"
                            placeholder="Your Email"
                            value={formData.email}
                            onChange={handleChange}
                            tabIndex={3}
                          />
                        </div>
                        <div className="group-8">
                          <img className="vector" alt="Vector" src="/img/vector-121.svg" />
                          <div className="text-wrapper-8">Last Name</div>
                          <input
                            type="text"
                            name="lastName"
                            placeholder="Your Last Name"
                            value={formData.lastName}
                            onChange={handleChange}
                            tabIndex={2}
                          />
                        </div>
                        <div className="group-9">
                          <img className="vector-3" alt="Vector" src="/img/vector-124.svg" />
                          <div className="text-wrapper-8">Message</div>
                          <textarea
                            name="message"
                            placeholder="Write your message.."
                            value={formData.message}
                            onChange={handleChange}
                            tabIndex={4}
                          />
                        </div>
                      </div>
                    </div>
                    <button className="group-10" onClick={handleSubmit}>
                      <div className="overlap-5">
                        <div className="text-wrapper-12">Send Message</div>
                      </div>
                    </button>
                    <img className="frame-2" alt="Frame" src="/img/frame.svg" />
                  </div>
                </div>
              </div>
            </div>
            <div className="group-11">
              <p className="p">Any question or remarks? Just write us a message!</p>
              <div className="text-wrapper-13">Contact Us</div>
              <div className="rectangle" />
            </div>
          </div>
        </div>
        {/*<div className="overlap-6">
          <img className="rectangle-2" alt="Rectangle" src="/img/rectangle-65.png" />
          <img className="rectangle-3" alt="Rectangle" src="/img/rectangle-65.png" />
        </div>*/}
        <div className="group-12">
          <div className="overlap-7">
            <p className="text-wrapper-14">
              © 2023 Kiu AI Systems, Inc. All rights reserved
            </p>
            <div className="group-13">
              <a
                href="https://maps.app.goo.gl/n1EU8h2FX28v1ZH46"
                className="text-wrapper-15"
                target="_blank"
                rel="noopener noreferrer"
              >
                4242 Six Forks Rd, Suite 1550 Raleigh, NC 27609
              </a>
              <img className="vector-4" alt="Vector" src="/img/vector-2.svg" />
            </div>
            <div className="group-14">
              <a
                className="text-wrapper-16"
                href="https://www.kiu.ai"
                target="_blank"
                rel="noopener noreferrer"
              >
                WWW.KIU.AI
              </a>
              <img className="vector-5" alt="Vector" src="/img/vector-1.svg" />
            </div>
            <div className="group-15">
              <a href="tel:+17866028048" className="text-wrapper-17">
                +1 (786) 602-8048
              </a>
              <img className="group-16" alt="Group" src="/img/group.svg" />
            </div>
            <div className="group-17">
              <a href="mailto:Adrian@KIU.ai" className="text-wrapper-18">Adrian@KIU.ai</a>
              <img className="vector-6" alt="Vector" src="/img/vector.svg" />
            </div>
            <div className="rectangle-4" />
            <div className="rectangle-5" />
            <div className="rectangle-6" />
          </div>
        </div>
      </div>
      {/* Loading screen */}
      {loading && <div className="loading">Submitting...</div>}
      {/* Popup to display success or error message */}
      {showPopup && (
        <div className={`popup ${status}`}>
          {status === "success" ? "Message sent successfully!" : "Error sending message."}
        </div>
      )}
    </div>
  );
};
