  import React from "react";
import "./style.css";
import { Link } from "react-router-dom";

export const ProductPage = () => {
  return (
    <div className="product-page">
      <div className="div">
        <div className="group">
          <div className="navbar">
            <Link to="/"><div className="text-wrapper">Product</div></Link>
            <Link to="/services"><div className="text-wrapper-2">Services</div></Link>
            <Link to="/about"><div className="text-wrapper-3">About Us</div></Link>
            <Link to="/investors"><div className="text-wrapper-4">For Investors</div></Link>
            <div className="overlap-group-wrapper">
              <div className="overlap-group">
                <Link to="/Contact"><div className="text-wrapper-5">Contact</div></Link>
              </div>
            </div>
          </div>
        </div>
        <div className="group-2">
          <div className="div-wrapper">
            <div className="overlap-group-2">
              <div className="text-wrapper-6">Key Features</div>
            </div>
          </div>
          <div className="overlap-wrapper">
            <div className="overlap-2">
              <div className="group-3">
                <div className="text-wrapper-7">Automated Processes</div>
                <div className="flexcontainer">
                  <p className="span-wrapper">
                    <span className="span">
                      Transforming manual QA tasks into automated operations, saving time and resources.
                      <br />
                    </span>
                  </p>
                </div>
                <img className="frame" alt="Frame" src="/img/frame-11.svg" />
              </div>
              <div className="group-4">
                <p className="p">
                  The QA Validation Copilot offers a unique AI-powered solution, enhancing computer systems validation
                  and risk management with industry best practices.
                </p>
                <div className="text-wrapper-8">Unique Solution</div>
                <img className="img" alt="Frame" src="/img/frame-1.svg" />
              </div>
              <div className="group-5">
                <div className="flexcontainer-2">
                  <p className="text">
                    <span className="span">
                      Our AI is an expert in compliance, ensuring thorough adherence to regulations.
                      <br />
                    </span>
                  </p>
                </div>
                <div className="text-wrapper-9">Deep Regulatory Knowledge</div>
                <img className="frame-2" alt="Frame" src="/img/frame-7.svg" />
              </div>
              <div className="group-6">
                <div className="text-wrapper-10">First-Mover Advantage</div>
                <div className="flexcontainer-3">
                  <p className="text">
                    <span className="span">
                      Leading the industry into a new era of compliance technology.
                      <br />
                    </span>
                  </p>
                </div>
                <img className="frame-3" alt="Frame" src="/img/frame-6.svg" />
              </div>
              <div className="group-7">
                <div className="text-wrapper-11">User-Friendly Design</div>
                <p className="simplifying-adoption">
                  {" "}
                  Simplifying adoption with an interface that professionals can navigate with ease.
                </p>
                <img className="frame-4" alt="Frame" src="/img/frame-5.svg" />
              </div>
              <img className="vector" alt="Vector" src="/img/vector-1-1.svg" />
            </div>
          </div>
        </div>
        <div className="group-8">
          <div className="group-9">
            <div className="group-10">
              <div className="overlap-group-3">
                <div className="text-wrapper-12">APR Generator</div>
              </div>
            </div>
            <div className="group-11">
              <div className="flexcontainer-wrapper">
                <div className="flexcontainer-4">
                  <p className="text-2">
                    <span className="text-wrapper-13">
                      Unlock the potential of your pharmaceutical operations with our &#39;APR Generator&#39; software
                      subscription. <br />
                    </span>
                  </p>
                  <p className="text-2">
                    <span className="text-wrapper-13">
                      This robust tool is engineered to revolutionize the way you handle Annual Product Reviews,
                      bringing automation and efficiency to the forefront of regulatory compliance. <br />
                    </span>
                  </p>
                  <p className="text-2">
                    <span className="text-wrapper-13">
                      With &#39;APR Generator,&#39; you’re not just keeping up with industry standards; you’re setting
                      them.
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="group-12">
            <div className="group-10">
              <div className="overlap-group-4">
                <div className="text-wrapper-14">QA Validation/Copilot</div>
              </div>
            </div>
            <div className="group-11">
              <div className="flexcontainer-wrapper">
                <div className="flexcontainer-5">
                  <p className="text-2">
                    <span className="text-wrapper-13">
                      QA Validation Copilot is an AI-powered solution design to provide answers to questions related
                      to Computer Systems Validation and Risk Management for Computer Systems. <br />
                    </span>
                  </p>
                  <p className="text-2">
                    <span className="text-wrapper-13">
                      With a body of knowledge based on industry&#39;s best practices, the QA Validation Copilot is
                      the perfect complement to junior resources while also providing value to more experienced
                      resources. <br />
                    </span>
                  </p>
                  <p className="text-2">
                    <span className="text-wrapper-13">
                      The QA Validation Copilot will soon be updated to provide assistance writing select validation
                      deliverables, such as change control records and validation plans.
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="group-13">
          <div className="group-14">
            <div className="overlap-group-4">
              <div className="vision-mission">Vision &amp; Mission</div>
            </div>
          </div>
          <div className="group-15">
            <div className="group-wrapper">
              <div className="group-16">
                <img className="frame-5" alt="Frame" src="/img/frame-4.svg" />
                <div className="text-wrapper-15">Mission</div>
                <p className="text-wrapper-16">
                  To make life-saving medications affordable, streamlining compliance to reduce costs without
                  compromising quality or safety.
                </p>
              </div>
            </div>
            <div className="group-17">
              <div className="group-18">
                <img className="frame-6" alt="Frame" src="/img/frame-3.svg" />
                <div className="text-wrapper-17">Vision</div>
                <p className="text-wrapper-18">
                  To empower healthcare with advanced AI, leading FDA compliance and revolutionizing the industry
                  through smart technology.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="group-19">
          <div className="overlap-3">
            <div className="group-20">
              <div className="group-10">
                <div className="overlap-group-2">
                  <div className="text-wrapper-19">The Problem We Address</div>
                </div>
              </div>
              <p className="FDA-compliance">
                FDA Compliance: Ensuring products meet regulations to avoid market rejection.
                <br />
                <br />
                Cost of Non-compliance: Mitigating the steep financial risks associated with FDA non-compliance.
                <br />
                <br />
                Demand on Resources: Reducing the heavy load on QA and Regulatory professionals.
                <br />
                <br />
                Complex Annual Reviews: Streamlining the exhaustive APR filing process.
                <br />
                <br />
                Collaborative Challenges: Facilitating smoother cross-functional collaboration for APR compilation.
              </p>
            </div>
            <div className="group-21">
              <div className="group-10">
                <div className="overlap-group-5">
                  <div className="text-wrapper-20">Our Innovative Solution</div>
                </div>
              </div>
              <p className="AI-driven-efficiency">
                AI-Driven Efficiency: Our system auto-generates APR reports, cutting down on manual labor.
                <br />
                <br />
                Cost-Effectiveness: Dramatically reducing the time and resources needed for compliance.
                <br />
                <br />
                Impact on Healthcare: Lowering drug costs and enhancing patient access to essential medications.
              </p>
            </div>
            <img className="frame-7" alt="Frame" src="/img/frame-2.svg" />
          </div>
        </div>
        <a href="/">
          <img className="logo" alt="Logo" src="/img/logo.svg" />
        </a>
        <div className="group-22">
          <div className="overlap-4">
            <div className="group-23">
              <div className="overlap-group-6">
                <p className="revolutionizing">
                  <span className="text-wrapper-21">Revolutionizing</span>
                  <span className="text-wrapper-22">&nbsp;</span>
                  <span className="text-wrapper-23">Pharmaceutical Compliance with Pioneering AI</span>
                </p>
                <p className="navigate-regulatory">
                  Navigate regulatory landscapes <br />
                  with ease and precision
                </p>
              </div>
              <p className="text-wrapper-24">
                Kiu AI Systems, Inc. is at the forefront of integrating artificial intelligence into pharmaceutical
                regulatory compliance, a niche yet vital domain. Our AI-driven system, designed for intuitiveness,
                simplifies the complexity of compliance, bringing efficiency and automation to the forefront of
                quality assurance
              </p>
              <div className="rectangle" />
            </div>
            <img className="group-24" alt="Group" src="/img/group-1.svg" />
          </div>
        </div>
        <div className="group-25">
          <div className="overlap-5">
            <p className="text-wrapper-25">
              © 2023 Kiu AI Systems, Inc. All rights reserved
            </p>
            <div className="group-26">
              <a
                href="https://maps.app.goo.gl/n1EU8h2FX28v1ZH46"
                className="text-wrapper-26"
                target="_blank"
                rel="noopener noreferrer"
              >
                4242 Six Forks Rd, Suite 1550 Raleigh, NC 27609
              </a>
              <img className="vector-2" alt="Vector" src="/img/vector-2.svg" />
            </div>
            <div className="group-27">
              <a
                className="text-wrapper-27"
                href="https://www.kiu.ai"
                target="_blank"
                rel="noopener noreferrer"
              >
                WWW.KIU.AI
              </a>
              <img className="vector-3" alt="Vector" src="/img/vector-1.svg" />
            </div>
            <div className="group-28">
              <a href="tel:+17866028048" className="text-wrapper-28">
                +1 (786) 602-8048
              </a>
              <img className="group-29" alt="Group" src="/img/group.svg" />
            </div>
            <div className="group-30">
              <a href="mailto:Adrian@KIU.ai" className="text-wrapper-29">Adrian@KIU.ai</a>
              <img className="vector-4" alt="Vector" src="/img/vector.svg" />
            </div>
            <div className="rectangle-2" />
            <div className="rectangle-3" />
            <div className="rectangle-4" />
          </div>
        </div>
      </div>
    </div>
  );
};